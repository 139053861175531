"use client";

import { createContext, useContext, useState } from "react";

const loginData = createContext(null);

export const LoginProvider = ({ children }) => {
  const [userId, setUserId] = useState(null);
  const [userType, setUserType] = useState(null);
  return (
    <loginData.Provider value={{ userId, setUserId, userType, setUserType }}>
      {children}
    </loginData.Provider>
  );
};

export const useLoginData = () => useContext(loginData);
