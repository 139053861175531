import { Box, CardHeader, Flex, Heading, Text } from "@chakra-ui/react";

const UnderMaintenancePage = () => {
  return (
    <Flex
      minH="100vh"
      align="center"
      justify="center"
      bgColor="black"
      color="white" // Set the desired background color here
    >
      <Box p={8} maxW="md" borderWidth={1} borderRadius="md" boxShadow="md">
        <Heading as="h1" size="lg" mb={6} color="gray.800">
          Site Under Maintenance
        </Heading>
        <Text color="gray.600" my={20}>
          We apologize for the inconvenience, but our site is currently
          undergoing maintenance. We'll be back soon!
        </Text>
        <Text color="gray.600" mb={4}>
          For urgent matters, please contact us at{" "}
          <Text as="a" href="mailto:aptofycare@unlimiteye.com" color="blue.500">
            aptofycare@unlimiteye.com
          </Text>
        </Text>
      </Box>
    </Flex>
  );
};

export default UnderMaintenancePage;
