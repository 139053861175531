import { LoginProvider } from "../context/context";
import HeaderPage from "./header";


const Layout = ({ children }) => (
  <>
    <LoginProvider>
      
      <HeaderPage></HeaderPage>
      <main>{children}</main>
    </LoginProvider>
  </>
);

export default Layout;
