import Head from 'next/head';

const MetaTags = () => {
  // Check if the environment is development
  const isDevelopment = process.env.NODE_ENV === 'development';

  // Conditionally render the meta tag based on the environment
  return (
    <Head>
  {isDevelopment && (
        <>
          <meta name="robots" content="noindex" />
          <meta name="robots" content="nofollow" />
        </>
      )}
    </Head>
  );
};

export default MetaTags;