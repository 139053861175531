import api from "../../config/axios";

export const isServerOnline = async () => {
  try {
    const response = await api.get(`/v1/hello`);
    return response;
  } catch (error) {
    error.message = error.response && error.response.data.message;
    throw error;
  }
};
