import MetaTags from "../components/MetaTags";
import "../styles/globals.css";
import { ChakraProvider, ColorModeProvider, useColorMode } from "@chakra-ui/react";

import "semantic-ui-css/semantic.min.css";
import "react-quill/dist/quill.snow.css";
import ErrorBoundary from './ErrorBoundary';
import Layout from "../components/layout";
import Maintenance from "./maintenance";
import { useState } from "react";
import { isServerOnline } from "./api/serverCheck";
import theme from "../theme";

export default function App({ Component, pageProps }) {
  const { colorMode } = useColorMode();

  const [isUnderMaintenance, setIsUnderMaintenance] = useState(false);
  const test = async () => {
    try {
      const response = await isServerOnline();
      // console.log(response.data["success"], "Backend Server Status");
      if (!response.data["success"]) {
        console.log("Did not receive a response from Backend Server");
        setIsUnderMaintenance(true);
      }
    } catch (error) {
      // console.log(error);
      console.log("Did not receive a response from Backend Server");
      setIsUnderMaintenance(true);
    } finally {
    }
  };
  test();
  return (
    <ChakraProvider theme={theme}>
    <ColorModeProvider options={{ initialColorMode: "light", useSystemColorMode: true }}>
    <MetaTags />
      <ErrorBoundary fallback={<p>Loading...</p>}>
        {false ? (
          <Maintenance />
        ) : (
          <Layout>
            <Component {...pageProps} />
          </Layout>
        )}
      </ErrorBoundary>
      </ColorModeProvider>
    </ChakraProvider>
  );
}
