import { useState, useEffect } from 'react';

function ErrorBoundary({ fallback, children }) {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const onError = (error, info) => {
     
      logErrorToMyService(error, info.componentStack);
      setHasError(true);
    };

    window.addEventListener('error', onError);

    return () => {
      window.removeEventListener('error', onError);
    };
  }, []);

  if (hasError) {
    // Render your fallback UI
    return fallback;
  }

  return children;
}

export default ErrorBoundary;
